<template>
  <v-app>

    <v-navigation-drawer
        v-model="drawerRight"
        app
        clipped
        right
    >

      <v-row
          v-if="$route.name === 'Campaigns'"
          justify="center"
          align="center"
          class="pa-2"
      >
        <!--        <v-col cols="1">-->
        <!--          <v-btn class="ml-6" fab elevation="0" dark small color="primary">-->
        <!--            <v-icon dark>mdi-plus</v-icon>-->
        <!--          </v-btn>-->
        <!--        </v-col>-->
        <!--        <v-col cols="12">-->
        <!--          <v-select-->
        <!--              hide-details-->
        <!--              label="User"-->
        <!--              dense-->
        <!--              solo-->
        <!--              :items="['All','miro']"-->
        <!--              :value="'All'"-->
        <!--          ></v-select>-->
        <!--        </v-col>-->

        <!--        <v-col cols1="12">-->
        <!--          <v-text-field-->
        <!--              hide-details-->
        <!--              label="Campaign ID"-->
        <!--              type="st"-->
        <!--              min="-1"-->
        <!--              max="15"-->
        <!--          ></v-text-field>-->

        <!--        </v-col>-->

        <!--        <v-col cols="12">-->
        <!--          <v-text-field-->
        <!--              hide-details-->
        <!--              label="Parent Campaign ID"-->
        <!--              type="st"-->
        <!--              min="-1"-->
        <!--              max="15"-->
        <!--          ></v-text-field>-->
        <!--        </v-col>-->

        <v-col cols="12">
          <v-select
              class="mr-0"
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              hide-details
              label="Per page"
              dense
          ></v-select>
        </v-col>
        <v-col cols="12" class="text-center" align-self="center">
          <v-autocomplete
              class="mr-0 mb-1"
              :items="cabItems"
              item-text="name"
              dense
              label="Cab"
              clearable
              v-model="cabValue"
              return-object
          ></v-autocomplete>

          <!--          <v-autocomplete-->
          <!--              class="mr-0 my-1"-->
          <!--              :items="stasOffersItems"-->
          <!--              item-text="name"-->
          <!--              dense-->
          <!--              label="Main offer"-->
          <!--              clearable-->
          <!--              v-model="stasOfrValue"-->
          <!--          ></v-autocomplete>-->

          <!--          <v-autocomplete-->
          <!--              class="mr-0 my-1"-->
          <!--              :items="parentOffersItems"-->
          <!--              item-text="name"-->
          <!--              dense-->
          <!--              label="Parent offer"-->
          <!--              clearable-->
          <!--              v-model="parentOffer"-->
          <!--          ></v-autocomplete>-->
          <v-switch label="Disabled Autoprice" v-model="autoPriceIsOff"></v-switch>
          <v-switch label="Disabled Optimization" v-model="otiIsOff"></v-switch>
          <v-switch label="On Moderation" v-model="onModeration"></v-switch>
          <v-switch v-model="optimizationTableMassActionEnableStatus" v-if="onModeration && getIsAdmin()">
            <template v-slot:label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click.stop="optimizationTableMassActionStatus = true" v-on="on"
                         :disabled="!(optimizationTableMassActionEnableStatus && optimizationTableMassActionAllow)">Mass
                    actions
                  </v-btn>
                </template>
                <span>Mass actions</span>
              </v-tooltip>

            </template>

          </v-switch>

        </v-col>


      </v-row>

      <v-row
          v-if="$route.name === 'DSPTeasers'"
          justify="center"
          align="center"
          class="pa-2"
      >
        <v-col cols="12">
          <v-switch label="Only empty Tags" v-model="filterDSPShowOnlyEmptyTags"></v-switch>
        </v-col>

        <v-col cols="12" v-if="getUserName() === 'denis' || getUserName() === 'miro' || getUserName() === 'lerksya'">
          <v-btn @click="isDspTeasersMassChanges = true" :disabled="!isAllowDspTeasersAllowMassChanges" color="primary">
            Mass changes
          </v-btn>
        </v-col>

        <!--        <v-col cols="12" v-if="getUserName() === 'denis' || getUserName() === 'miro' || getUserName() === 'lerksya'">-->
        <v-col cols="12" class="ml-6">
          <v-row>

            <v-switch label="Display statistics" v-model="isVisibleStat"></v-switch>
          </v-row>
          <v-row>
            <v-switch label="From Aff Network" v-model="isShowStatFromDsp" :disabled="!isVisibleStat"></v-switch>
          </v-row>
        </v-col>
        <v-col cols="12" class="ml-6">
          <v-row>
            <v-file-input
                v-model="uploadParserFile"
                label="Upload File for Parser"
                :truncate-length="100"
                clearable
            ></v-file-input>
          </v-row>
          <v-btn color="primary" :disabled="!uploadParserFile" @click.stop="onUploadFile">Upload</v-btn>
        </v-col>
      </v-row>

      <!--      <v-list dense>-->
      <!--        <v-list-item @click.stop="right = !right">-->
      <!--          <v-list-item-action>-->
      <!--            <v-icon>mdi-exit-to-app</v-icon>-->
      <!--          </v-list-item-action>-->
      <!--          <v-list-item-content>-->
      <!--            <v-list-item-title>Open Temporary Drawer REGHT</v-list-item-title>-->
      <!--          </v-list-item-content>-->
      <!--        </v-list-item>-->
      <!--      </v-list>-->
    </v-navigation-drawer>

    <v-app-bar
        app
        dense
        clipped-left
        clipped-right
        color="primary"
        dark
        ref="toolbar" v-mutate="onMutate"
    >
      <v-app-bar-nav-icon @click.stop="ifaceDrawerLeftSwitch"></v-app-bar-nav-icon>

      <!--      <v-row>-->

      <!--      </v-row>-->

      <v-toolbar-title>{{ getTitle() }}</v-toolbar-title>

      <v-col cols="2" sm="2" md="2" v-if="$route.name === 'DSPTeasers'" class="ml-4">
        <v-select :items="depsSources" dense flat solo solo-inverted hide-details
                  :value="$route.params.section"
                  :item-value="'text'"
                  @input="$router.push('/DSP/Teasers/'+ $event)">
        </v-select>
      </v-col>

      <v-tooltip bottom v-if="$route.name === 'Campaigns'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="ifaceOptimizationAddItemWindowSwitch()"
              class="mx-7" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Campaign for Optimization</span>
      </v-tooltip>


      <v-tooltip bottom v-if="$route.name === 'Campaigns'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="ifaceOptimizationAddNewTaskWindowSwitch()"
              class="mx-7" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add new Task</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'DSPTeasers'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              @click="ifaceDspTeasersAddItemWindowSwitch()"
              class="ml-5 mr-7" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Teaser</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'AffNetwork'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              @click="ifaceAffNetsAddItemWindowSwitch()"
              class="ml-5 mr-2" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add campaign</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'AffNetwork'">
        <template v-slot:activator="{ on, attrs }">
          <v-badge

              icon="mdi-tab"
              overlap
          >
            <v-btn
                v-on="on"
                v-bind="attrs"
                :to="$route.fullPath+'?createNewFlow=true'"
                target='_blank'
                fab elevation="0" small color="blue darken-3"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>Add campaign(New Window)</span>
      </v-tooltip>


      <v-tooltip bottom v-if="$route.name === 'FlowTable'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              @click="ifaceApiAddItemWindowSwitch()"
              class="ml-5 mr-5" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Flow</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'EarningsNetworks'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              @click="ifaceEarningAddStatisticWindowSwitch()"
              class="mx-7" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-upload</v-icon>
          </v-btn>
        </template>
        <span>Add or update Earning from Network</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'DSPTeasers'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="refreshDspTeasersTable = true"
              :disabled="dspTeasersTableIsLoading"
              class="ml-0" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'Campaigns'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="refreshOptimizationTable = true"
              :disabled="optimizationTableIsLoading"
              class="ml-0" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>


      <v-tooltip bottom v-if="$route.name === 'AffNetwork'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="refreshAffNetsTable = true"
              :disabled="affNetsTableIsLoading"
              class="ml-3" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'Sources'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="refreshSourceCampaigns = true"
              :disabled="refreshSourceCampaignsIsLoading"
              class="ml-3" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'Sources'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="forceRefreshSourceCampaigns = true"
              :disabled="refreshSourceCampaignsIsLoading || !isAllowRefreshCampaigns"
              class="ml-3" fab elevation="0" small color="green darken-3">
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh Campaigns</span>
      </v-tooltip>


      <span
          v-if="$route.name === 'AffNetwork' && !affNetsTableIsLoading && $store.getters['iface/getFinalizedDates'].length>0"
          class="pl-4">
        Last finalized date: {{ $store.getters['iface/getFinalizedDates'][1].date }}
      </span>


      <v-tooltip bottom
                 v-if="$route.name === 'AffNetwork' && !affNetsTableIsLoading && $store.getters['iface/getFinalizedDates'].length>0">
        <template v-slot:activator="{ on }">
          <span v-if="(Date.now()/1000 - $store.getters['iface/getFinalizedDates'][0].timestamp)/60 > 20" v-on="on"
                class="ml-2 px-4 red">
<!--            statistics last updated {{ Math.ceil((Date.now() - $store.getters['iface/getFinalizedDates'][0].timestamp) / 60) }} minutes ago -->
            statistics last updated {{
              Math.ceil((Date.now() / 1000 - $store.getters['iface/getFinalizedDates'][0].timestamp) / 60)
            }} minutes ago
          </span>
          <div v-on="on" v-else>
            <span class="pl-4">statistics last updated</span>
            <v-avatar color="green" class="ml-1" size="32">
            <span class="white--text Heading">{{
                Math.ceil((Date.now() / 1000 - $store.getters['iface/getFinalizedDates'][0].timestamp) / 60)
              }}</span>
            </v-avatar>

            <span> minutes ago </span>
          </div>
        </template>
        <span>Last date: {{ $store.getters['iface/getFinalizedDates'][0].date }}</span>
      </v-tooltip>


      <v-tooltip bottom v-if="$route.name === 'FlowTable'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="refreshFlowTable = true"
              :disabled="flowTableIsLoading"
              class="ml-0" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip bottom v-if="$route.name === 'Campaigns'">
        <template v-slot:activator="{ on }">
          <v-switch label="On Moderation" v-model="onModeration" v-on="on" class="pt-6 pl-7"></v-switch>
        </template>
        <span>Offers on moderation</span>
      </v-tooltip>

      <v-tooltip bottom v-else-if="$route.name === 'DSPTeasers'">
        <template v-slot:activator="{ on }">
          <v-switch label="On Moderation" v-model="dspTeasersOnModeration" v-on="on" class="pt-6 pl-7"></v-switch>
        </template>
        <span>Teasers on moderation</span>
      </v-tooltip>

      <v-select v-if="$route.name === 'DSPTeasers' && dspDepsAndTeams.length > 1"
                light
                hide-details
                dense
                solo
                flat
                :items="dspDepsAndTeams"
                return-object
                v-model="dspDep"
                clearable>
      </v-select>

      <v-toolbar-title class="pl-1" v-if="$route.name === 'SpendingTotal'">last updated</v-toolbar-title>
      <v-avatar color="green" class="ml-1" size="32" v-if="$route.name === 'SpendingTotal' && spendingLastUpdate<40">
        <span class="white--text Heading">{{ spendingLastUpdate }}</span>
      </v-avatar>
      <v-avatar color="red" class="ml-1" size="32" v-if="$route.name === 'SpendingTotal' && spendingLastUpdate>40">
        <span class="white--text Heading">{{ spendingLastUpdate }}</span>
      </v-avatar>
      <v-toolbar-title class="ml-1" v-if="$route.name === 'SpendingTotal'">min ago</v-toolbar-title>


      <!--      <v-toolbar-title class="pl-1" v-if="$route.name === 'SpendingCampaigns'">last updated</v-toolbar-title>-->
      <!--      <v-avatar color="green" class="ml-1" size="32"-->
      <!--                v-if="$route.name === 'SpendingCampaigns' && spendingCampaignsLastUpdate<30">-->
      <!--        <span class="white&#45;&#45;text Heading">{{ spendingCampaignsLastUpdate }}</span>-->
      <!--      </v-avatar>-->
      <!--      <v-avatar color="red" class="ml-1" size="32"-->
      <!--                v-if="$route.name === 'SpendingCampaigns' && spendingCampaignsLastUpdate>30">-->
      <!--        <span class="white&#45;&#45;text Heading">{{ spendingCampaignsLastUpdate }}</span>-->
      <!--      </v-avatar>-->
      <!--      <v-toolbar-title class="ml-1 mr-8" v-if="$route.name === 'SpendingCampaigns'">min ago</v-toolbar-title>-->


      <v-tooltip bottom v-if="$route.name === 'SpendingCampaigns'">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              @click="refreshSpendingCampaignsTable = true"
              :disabled="spendingCampaignsTableIsLoading"
              class="ml-0" fab elevation="0" small color="blue darken-3">
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>


      <v-tooltip bottom v-if="$route.name === 'SpendingCampaigns' && isRunningBackdatingUpdateSpent">
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              class="ml-7" fab elevation="0" small color="red darken-3">
            <v-icon dark>mdi-alert-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>
          Running: {{ dateFormat(runningBackdatingUpdateSpentStatus.startingTime) }}<br>
          Start date: {{ runningBackdatingUpdateSpentStatus.startDate }}<br>
          End date: {{ runningBackdatingUpdateSpentStatus.endDate }}<br>
          Total: {{ runningBackdatingUpdateSpentStatus.totalDays }}<br>
          Current: {{ runningBackdatingUpdateSpentStatus.current }}<br>
          Left: {{ runningBackdatingUpdateSpentStatus.totalDays - runningBackdatingUpdateSpentStatus.current }}<br>
        </span>
      </v-tooltip>

      <v-toolbar-title class="pl-1" v-if="$route.name === 'ErrorInUrl'">last updated</v-toolbar-title>
      <v-avatar color="green" class="ml-1" size="32" v-if="$route.name === 'ErrorInUrl' && urlCheckerLastUpdate<90">
        <span class="white--text Heading">{{ urlCheckerLastUpdate }}</span>
      </v-avatar>
      <v-avatar color="red" class="ml-1" size="32" v-if="$route.name === 'ErrorInUrl' && urlCheckerLastUpdate>90">
        <span class="white--text Heading">{{ spendingLastUpdate }}</span>
      </v-avatar>
      <v-toolbar-title class="ml-1" v-if="$route.name === 'ErrorInUrl'">min ago</v-toolbar-title>


      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ timeInLA }}</span>
        </template>
        <span>In Los Angeles: <p class="text-center mb-0">{{ dateInLA }}<br> {{ timeInLA }}</p></span>
      </v-tooltip>
      <v-app-bar-nav-icon @click.stop="ifaceDrawerRightSwitch"></v-app-bar-nav-icon>

      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-tooltip bottom v-if="$store.getters['user/getTelegramUserId'] === 0">
              <template v-slot:activator="{ on }">
                <v-badge

                    color="red"
                    left overlap
                    bottom
                >
                  <v-avatar :size="24">
                    <img v-on="on" :src="getAvatar()" :alt="getGoogleGivenName()">
                  </v-avatar>
                </v-badge>
              </template>
              <span>Link account to Telegram Bot</span>
            </v-tooltip>

            <v-avatar :size="32" v-else>
              <img v-on="on" :src="getAvatar()" :alt="getGoogleGivenName()">
            </v-avatar>
          </v-btn>


        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img :src="getAvatar()" :alt="getGoogleGivenName()">
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ getGoogleName() }} ({{ getUserName() }})</v-list-item-title>
                <v-list-item-subtitle>{{ getPermissions() }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                    :class="fav ? 'red--text' : ''"
                    icon
                    @click="fav = !fav"
                >
                  <!--                  <v-icon>mdi-heart</v-icon>-->
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <!--            <v-list-item>-->
            <!--              <v-list-item-action>-->
            <!--                <v-switch v-model="message" color="purple"></v-switch>-->
            <!--              </v-list-item-action>-->
            <!--              <v-list-item-title>Enable messages</v-list-item-title>-->
            <!--            </v-list-item>-->

            <!--            <v-list-item>-->
            <!--              <v-overflow-btn-->
            <!--                  :items="['English', 'Russian']"-->
            <!--                  label="Language"-->
            <!--              ></v-overflow-btn>-->
            <!--            </v-list-item>-->

            <v-list-item>
              <v-select solo
                        :items="[{text: 'English', value: 'EN' }, {text: 'Russian', value: 'RU'}]"
                        label="Language"
                        v-model="locale"
              ></v-select>
            </v-list-item>

            <v-list-item v-if="$store.getters['user/getTelegramUserId'] !== 0">
              <v-list-item-title>Go to chat: {{ $store.getters['user/getTelegramBotName'] }}</v-list-item-title>
              <v-list-item-action>
                <v-btn color="primary" text :href="'https://t.me/'+$store.getters['user/getTelegramBotName']"
                       target="_blank">
                  <v-icon>mdi-robot</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-else>
              <v-list-item-title class="red--text">Link account with {{ $store.getters['user/getTelegramBotName'] }} !
              </v-list-item-title>
              <v-list-item-action>
                <v-btn color="primary" text @click="onLinkTelegramAccount">
                  <v-icon>mdi-robot</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>

            <!--            <v-btn text @click="menu = false">Cancel</v-btn>-->
            <v-btn color="primary" text @click="dialog = true">
              <!--              Logout-->
              <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawerLeft"
        app
        clipped
        left
        :mini-variant="miniVariant"
        :expand-on-hover="miniVariant"
    >
      <v-list-item-group dense color="primary">
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="mdi-airplane-takeoff" no-action v-if="isVisibleOptimisation"
                      group="\/Optimization.*" value="true">
          <template v-slot:activator>
            <v-list-item-title>Optimization</v-list-item-title>
          </template>
          <v-list-item to="/Optimization/Campaigns">
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted-type</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Optimization</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/Optimization/ErrorInUrl">
            <v-list-item-action>
              <v-icon>mdi-alert-decagram-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Error in URL</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/Optimization/Statistic">
            <v-list-item-icon>
              <v-icon>mdi-table-large</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Statistic</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <!--        <v-list-item to="/Spending" v-if="isVisibleCabStat">-->
        <!--          <v-list-item-action>-->
        <!--            <v-icon>mdi-account-cash</v-icon>-->
        <!--          </v-list-item-action>-->
        <!--          <v-list-item-content>-->
        <!--            <v-list-item-title>Spending</v-list-item-title>-->
        <!--          </v-list-item-content>-->
        <!--        </v-list-item>-->


        <v-list-group prepend-icon="mdi-cash-minus" no-action v-if="isVisibleSpending"
                      group="\/Spending.*" value="true">
          <template v-slot:activator>
            <v-list-item-title>Spending</v-list-item-title>
          </template>

          <!--          <v-list-item to="Spending" v-if="isVisibleCabStat">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-account-cash</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Spending</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <v-list-item to="/Spending/Campaigns">
            <v-list-item-action>
              <v-icon>mdi-format-list-numbered</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Campaigns</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/Spending/Total">
            <v-list-item-action>
              <v-icon>mdi-office-building-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Total</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        </v-list-group>

        <v-list-group prepend-icon="mdi-arrow-all" no-action v-if="isVisibleSources"
                      group="\/Spending.*" value="true">
          <template v-slot:activator>
            <v-list-item-title>Sources</v-list-item-title>
          </template>

          <!--          <v-list-item to="Spending" v-if="isVisibleCabStat">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-account-cash</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Spending</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <v-list-item to="/Sources/Mgid">
<!--            <v-list-item-action>-->
<!--              <v-icon>mdi-format-list-numbered</v-icon>-->
<!--            </v-list-item-action>-->
            <v-list-item-content>
              <v-list-item-title>Mgid</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-cash-plus" no-action v-if="$store.getters['user/isVisibleEarningsStat']"
                      group="\/Earnings.*" value="true">
          <template v-slot:activator>
            <v-list-item-title>Earnings</v-list-item-title>
          </template>

          <!--          <v-list-item to="Spending" v-if="isVisibleCabStat">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-account-cash</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Spending</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <v-list-item to="/Earnings/Networks">
            <v-list-item-action>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Networks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--          <v-list-item to="/Spending/Total" v-if="isVisibleCabStat">-->
          <!--            <v-list-item-action>-->
          <!--              <v-icon>mdi-office-building-outline</v-icon>-->
          <!--            </v-list-item-action>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Total</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->


        </v-list-group>

        <v-list-group v-if="isVisibleDsp" prepend-icon="mdi-gavel" no-action
                      group="\/DSP.*" value="true">
          <template v-slot:activator>
            <v-list-item-title>DSP</v-list-item-title>
          </template>

          <!--          <v-list-item to="Spending" v-if="isVisibleCabStat">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-account-cash</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Spending</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->


          <v-list-item v-for="(item) in depsSources" :key="item.text" :to="'/DSP/Teasers/'+item.text">
            <!--            <v-list-item-icon>-->
            <!--              <v-icon>mdi-ballot-outline</v-icon>-->
            <!--            </v-list-item-icon>-->
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--          <v-list-item to="/DSP/Teasers/AdNow">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>AdNow</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/AdMixer">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>AdMixer</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/Adsterra">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Adsterra</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/Exoclick">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Exoclick</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/Geniee">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Geniee</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/Macaw">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Macaw</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/Mgid">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Mgid</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/Popin">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Popin</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/RevContent">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>RevContent</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-list-item to="/DSP/Teasers/Yengo">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-ballot-outline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Yengo</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->


          <!--          <v-list-item to="/DSP/Statistic" v-if="isVisibleCabStat">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-table-large</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Statistic</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

        </v-list-group>


        <v-list-group prepend-icon="mdi-api" no-action v-if="isVisibleApi"
                      group="\/API.*" value="true">
          <template v-slot:activator>
            <v-list-item-title>API</v-list-item-title>
          </template>

          <v-list-item to="/API/Flow">
            <v-list-item-action>
              <v-icon>mdi-wave</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Flow</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--          <v-list-item to="/API/Log">-->
          <!--            <v-list-item-action>-->
          <!--              <v-icon>mdi-notebook-edit</v-icon>-->
          <!--            </v-list-item-action>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Log</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

        </v-list-group>


        <!--        <v-list-group prepend-icon="mdi-cart-variant" no-action v-if="isVisibleAffNets || getUserName() === 'denis'"-->
        <!--                      group="\/AffNets.*" value="true">-->
        <!--          <template v-slot:activator>-->
        <!--            <v-list-item-title>Aff. Net</v-list-item-title>-->
        <!--          </template>-->

        <!--          <v-list-item v-for="(item) in affNets" :key="item" :to="'/AffNets/'+item">-->
        <!--            <v-list-item-content>-->
        <!--              <v-list-item-title>{{ item }}</v-list-item-title>-->
        <!--            </v-list-item-content>-->
        <!--          </v-list-item>-->

        <!--        </v-list-group>-->


        <v-list-group prepend-icon="mdi-cart-variant" no-action v-if="isVisibleAffNets"
                      group="\/AffNets.*" value="true">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Aff. Net</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-group v-for="(accounts, network) in affNets" :key="network" sub-group no-action value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ network }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="(account) in accounts"
                :key="network + '/'+ account"
                :to="'/AffNets/'+network + '/'+ account"
                link
            >
              <v-list-item-title v-text="account"></v-list-item-title>

              <!--              <v-list-item-icon>-->
              <!--                <v-icon v-text="icon"></v-icon>-->
              <!--              </v-list-item-icon>-->
            </v-list-item>
          </v-list-group>
        </v-list-group>


        <!--        <v-list-group v-if="getIsAdmin()" prepend-icon="mdi-account-supervisor" no-action>-->
        <!--          <template v-slot:activator>-->
        <!--            <v-list-item-title>AAA</v-list-item-title>-->
        <!--          </template>-->

        <!--          <v-list-item to="/AAA/Accounts">-->
        <!--            <v-list-item-icon>-->
        <!--              <v-icon>mdi-account-multiple-outline</v-icon>-->
        <!--            </v-list-item-icon>-->
        <!--            <v-list-item-content>-->
        <!--              <v-list-item-title>Accounts</v-list-item-title>-->
        <!--            </v-list-item-content>-->
        <!--          </v-list-item>-->

        <!--          <v-list-item to="/DSP/Statistic">-->
        <!--            <v-list-item-icon>-->
        <!--              <v-icon>mdi-account-group</v-icon>-->
        <!--            </v-list-item-icon>-->
        <!--            <v-list-item-content>-->
        <!--              <v-list-item-title>Groups</v-list-item-title>-->
        <!--            </v-list-item-content>-->
        <!--          </v-list-item>-->

        <!--        </v-list-group>-->

        <!--        <v-list-item >-->


        <!--            <v-list-group-->
        <!--                :value="true"-->
        <!--                no-action-->
        <!--                sub-group-->
        <!--            >-->
        <!--              <template v-slot:activator>-->
        <!--                <v-list-item-content>-->
        <!--                  <v-list-item-title>Admin</v-list-item-title>-->
        <!--                </v-list-item-content>-->
        <!--              </template>-->

        <!--              <v-list-item-->
        <!--                  v-for="([title, icon], i) in admins"-->
        <!--                  :key="i"-->
        <!--                  link-->
        <!--              >-->
        <!--                <v-list-item-title v-text="title"></v-list-item-title>-->

        <!--                <v-list-item-icon>-->
        <!--                  <v-icon v-text="icon"></v-icon>-->
        <!--                </v-list-item-icon>-->
        <!--              </v-list-item>-->
        <!--            </v-list-group>-->


        <!--          <v-list-item-action>-->
        <!--            <v-icon>mdi-gavel</v-icon>-->
        <!--          </v-list-item-action>-->
        <!--          <v-list-item-content>-->
        <!--            <v-list-item-title>Spending</v-list-item-title>-->
        <!--          </v-list-item-content>-->
        <!--        </v-list-item>-->


      </v-list-item-group>
    </v-navigation-drawer>

    <v-navigation-drawer
        v-model="left"
        fixed
        temporary
    ></v-navigation-drawer>

    <v-main v-if="$store.getters['user/isProfileLoaded']">
      <router-view/>


      <!--      <v-container-->
      <!--          class="fill-height"-->
      <!--          fluid-->
      <!--      >-->
      <!--        <router-view/>-->
      <!--      </v-container>-->
    </v-main>


    <v-navigation-drawer
        v-model="right"
        fixed
        right
        temporary
    ></v-navigation-drawer>

    <v-dialog
        v-model="dialog"
        width="580"
    >
      <v-card>
        <v-card-title
            class="headline grey lighten-2"
            primary-title
        >
          Logout...
        </v-card-title>

        <v-card-text>
          <span class="text-h6">Are you sure you want to leave this wonderful program?</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="error"
              @click="logout()"
          >
            Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import api from '@/utils/api'
import {AUTH_LOGOUT} from '@/store/actions/auth'
import {USER_REQUEST, USER_SET_LOCALE} from '@/store/actions/user'
import {
  IFACE_AFFNETS_ADD_ITEM_WINDOW_SWITCH,
  IFACE_AFFNETS_REFRESH_OFF,
  IFACE_AFFNETS_REFRESH_ON,
  IFACE_API_ADD_ITEM_WINDOW_SWITCH,
  IFACE_CAB_VALUE,
  IFACE_DRAWER_LEFT_OFF,
  IFACE_DRAWER_LEFT_ON,
  IFACE_DRAWER_LEFT_SWITCH,
  IFACE_DRAWER_RIGHT_OFF,
  IFACE_DRAWER_RIGHT_ON,
  IFACE_DRAWER_RIGHT_SWITCH,
  IFACE_DSP_IS_VISIBLE_STAT_FROM_DSP_OFF,
  IFACE_DSP_IS_VISIBLE_STAT_FROM_DSP_ON,
  IFACE_DSP_IS_VISIBLE_STAT_OFF,
  IFACE_DSP_IS_VISIBLE_STAT_ON,
  IFACE_DSP_SHOW_ONLY_EMPTY_TAGS_OFF,
  IFACE_DSP_SHOW_ONLY_EMPTY_TAGS_ON,
  IFACE_DSP_TEASERS_ADD_ITEM_WINDOW_SWITCH,
  IFACE_DSP_TEASERS_MASS_CHANGES_OFF,
  IFACE_DSP_TEASERS_MASS_CHANGES_ON,
  IFACE_DSP_TEASERS_ON_MODERATION_OFF,
  IFACE_DSP_TEASERS_ON_MODERATION_ON,
  // IFACE_DSP_TEASERS_REFRESH_OFF,
  IFACE_DSP_TEASERS_REFRESH_ON,
  IFACE_DST_SET_DEP,
  IFACE_DST_SET_DEPS,
  IFACE_EARNING_ADD_STATISTIC_WINDOW_SWITCH,
  IFACE_FLOW_REFRESH_OFF,
  IFACE_FLOW_REFRESH_ON,
  IFACE_ITEMS_PER_PAGE,
  IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_SWITCH,
  IFACE_OPTIMIZATION_ADD_NEW_TASK_OFF,
  IFACE_OPTIMIZATION_ADD_NEW_TASK_ON,
  IFACE_OPTIMIZATION_ADD_NEW_TASK_SWITCH,
  IFACE_OPTIMIZATION_FILTER_AUTOPRICE_OFF,
  IFACE_OPTIMIZATION_FILTER_AUTOPRICE_ON,
  IFACE_OPTIMIZATION_FILTER_OPTIOFF_OFF,
  IFACE_OPTIMIZATION_FILTER_OPTIOFF_ON,
  IFACE_OPTIMIZATION_ON_MODERATION_OFF,
  IFACE_OPTIMIZATION_ON_MODERATION_ON,
  IFACE_OPTIMIZATION_REFRESH_OFF,
  IFACE_OPTIMIZATION_REFRESH_ON, IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_OFF, IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_ON,
  IFACE_SOURCE_CAMPAIGNS_REFRESH_OFF,
  IFACE_SOURCE_CAMPAIGNS_REFRESH_ON,
  IFACE_SPENDING_CAMPAIGNS_REFRESH_OFF,
  IFACE_SPENDING_CAMPAIGNS_REFRESH_ON,
} from '../store/actions/iface'
import {DateTime} from 'luxon'
import {
  IFACE_DSP_TEASERS_SOURCES_SET,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_OFF,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_ON,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_LOADING_OFF,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_LOADING_ON,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_OFF,
  IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ON,
} from '../store/actions/iface'

export default {
  name: 'Index',

  // components: {
  //   HelloWorld,
  // },

  data: () => ({
    itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
    dialog: false,
    drawer: null,
    // drawerRight: false,
    right: false,
    left: false,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    miniVariant: false,
    timeInLA: -1,
    dateInLA: -1,
    uploadParserFile: null,
    // dspItems: ['AdNow', 'AdMixer', 'Adsterra', 'Exoclick', 'Geniee', 'Mgid', 'Macaw', 'Popin', 'RevContent', 'Yengo']
  }),

  created() {
    if (this.$store.getters['auth/isAuthenticated']) {
      this.$store.dispatch(USER_REQUEST)
    }
  },

  computed: {

    isVisibleOptimisation() {
      return this.$store.getters['user/isVisibleOptimisation']
    },
    isVisibleApi() {
      return this.$store.getters['user/isVisibleFlow']
    },
    isVisibleAffNets() {
      return this.$store.getters['user/isVisibleAffNets']
    },
    affNets() {
      return this.$store.getters['user/affNets']
    },
    isVisibleCabStat() {
      return this.$store.getters['user/isVisibleCabStat']
    },
    isVisibleSpending() {
      return this.$store.getters['user/isVisibleSpending']
    },

    isVisibleSources() {
      return this.$store.getters['user/isVisibleSources']
    },

    isVisibleDsp() {
      return this.$store.getters['user/isVisibleDsp']
    },
    spendingLastUpdate() {
      return this.$store.getters['iface/getSpendingLastUpdate']
    },
    // spendingCampaignsLastUpdate() {
    //   return this.$store.getters['iface/getSpendingCampaignsLastUpdate']
    // },
    urlCheckerLastUpdate() {
      return this.$store.getters['iface/getUrlCheckerLastUpdate']
    },

    isProfileLoaded: {
      get() {
        return this.$store.getters['user/isProfileLoaded']
      },
      set() {
      }
    },
    refreshOptimizationTable: {
      get() {
        return this.$store.getters['iface/getOptimizationRefreshStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_REFRESH_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_REFRESH_OFF)
      }
    },

    optimizationTableIsLoading: {
      get() {
        const status = this.$store.getters['iface/getOptimizationRefreshIsLoading']
        if (!status)
          this.$store.commit(IFACE_OPTIMIZATION_REFRESH_OFF)
        return this.$store.getters['iface/getOptimizationRefreshIsLoading']
      }
    },

    optimizationTableMassActionEnableStatus: {
      get() {
        return this.$store.getters['iface/getOptimizationTableMassActionEnableStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ENABLE_OFF)
      }
    },

    optimizationTableMassActionAllow: {
      get() {
        return this.$store.getters['iface/getOptimizationTableMassActionAllowStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ALLOW_OFF)
      }
    },

    optimizationTableMassActionStatus: {
      get() {
        return this.$store.getters['iface/getOptimizationTableMassActionStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_OFF)
      }
    },

    optimizationTableMassActionIsLoading: {
      get() {
        return this.$store.getters['iface/getOptimizationTableMassActionIsLoading']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_LOADING_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_TABLE_MASS_ACTION_LOADING_OFF)
      }
    },

    refreshSpendingCampaignsTable: {
      get() {
        return this.$store.getters['iface/getSpendingCampaignsRefreshStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SPENDING_CAMPAIGNS_REFRESH_ON)
        else
          this.$store.commit(IFACE_SPENDING_CAMPAIGNS_REFRESH_OFF)
      }
    },

    spendingCampaignsTableIsLoading: {
      get() {
        return this.$store.getters['iface/getSpendingCampaignsRefreshIsLoading']
      }
    },

    refreshDspTeasersTable: {
      get() {
        return this.$store.getters['iface/getDspTeasersRefreshStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DSP_TEASERS_REFRESH_ON)
        // else
        //   this.$store.commit(IFACE_DSP_TEASERS_REFRESH_OFF)
      }
    },

    dspTeasersTableIsLoading: {
      get() {
        // const status = this.$store.getters['iface/getDspTeasersRefreshIsLoading']
        // if (!status)
        //   this.$store.commit(IFACE_DSP_TEASERS_REFRESH_OFF)
        return this.$store.getters['iface/getDspTeasersRefreshIsLoading']
      }
    },

    refreshFlowTable: {
      get() {
        return this.$store.getters['iface/refreshFlowTable']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_FLOW_REFRESH_ON)
        else
          this.$store.commit(IFACE_FLOW_REFRESH_OFF)
      }
    },

    flowTableIsLoading: {
      get() {
        const status = this.$store.getters['iface/getFlowRefreshIsLoading']
        if (!status)
          this.$store.commit(IFACE_FLOW_REFRESH_OFF)
        return this.$store.getters['iface/getFlowRefreshIsLoading']
      }
    },

    refreshAffNetsTable: {
      get() {
        return this.$store.getters['iface/getAffNetsRefreshStatus']
      },
      set(value) {
        console.log('refreshAffNetsTable', value)
        try {
          if (value)
            this.$store.commit(IFACE_AFFNETS_REFRESH_ON)
          else
            this.$store.commit(IFACE_AFFNETS_REFRESH_OFF)
        } catch (e) {
          console.log('refreshAffNetsTable', e)
        }

      }
    },


    affNetsTableIsLoading: {
      get() {
        // const status = this.$store.getters['iface/getAffNetsRefreshIsLoading']
        // console.log('affNetsTableIsLoading', status)
        // if (!status)
        //   this.$store.commit(IFACE_AFFNETS_REFRESH_OFF)
        return this.$store.getters['iface/getAffNetsRefreshIsLoading']
      }
    },

    refreshSourceCampaigns: {
      get() {
        return this.$store.getters['iface/getRefreshSourcesCampaigns']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_REFRESH_ON)
        else
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_REFRESH_OFF)
      }
    },


    forceRefreshSourceCampaigns: {
      get() {
        return this.$store.getters['iface/getForceRefreshSourcesCampaigns']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_ON)
        else
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_OFF)
      }
    },

    refreshSourceCampaignsIsLoading: {
      get() {
        // const status = this.$store.getters['iface/getAffNetsRefreshIsLoading']
        // console.log('affNetsTableIsLoading', status)
        // if (!status)
        //   this.$store.commit(IFACE_AFFNETS_REFRESH_OFF)
        return this.$store.getters['iface/getSourceCampaignsIsLoading']
      }
    },

    isAllowRefreshCampaigns: {
      get() {
        return this.$store.getters['iface/getIsAllowRefreshSourcesCampaigns']
      }
    },

    isRunningBackdatingUpdateSpent: {
      get() {
        return this.$store.getters['iface/getIsRunningBackdatingUpdateSpent']
      }
    },

    runningBackdatingUpdateSpentStatus: {
      get() {
        return this.$store.getters['iface/getRunningBackdatingUpdateSpentStatus']
      }
    },

    drawerLeft: {
      get() {
        return this.$store.getters['iface/getDrawerLeft']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DRAWER_LEFT_ON)
        else
          this.$store.commit(IFACE_DRAWER_LEFT_OFF)
      }
    },
    itemsPerPage: {
      get() {
        return this.$store.getters['iface/getItemsPerPage']
      },
      set(value) {
        this.$store.commit(IFACE_ITEMS_PER_PAGE, value)
      }
    },

    locale: {
      get() {
        return this.$store.getters['user/getLocale']
      },
      set(value) {
        api.setLocale(value)
            .then(() => {
              this.$store.commit(USER_SET_LOCALE, value)
            })
            .catch(e => {
              window.console.error('setLocale error', e)
            })
        // if (value)
        //
        // else
        //   this.$store.commit(IFACE_DRAWER_LEFT_OFF)
      }
    },

    // parentOffer: {
    //   get () {
    //     return this.$store.getters['iface/parentOfferValue']
    //   },
    //   set (value) {
    //     this.$store.commit(IFACE_PARENT_OFFER_VALUE, value)
    //   }
    // },
    //
    // stasOfrValue: {
    //   get () {
    //     return this.$store.getters['iface/stasOfrValue']
    //   },
    //   set (value) {
    //     this.$store.commit(IFACE_STAS_OFR_VALUE, value)
    //   }
    // },
    //
    // parentOffersItems: {
    //   get () {
    //     return this.$store.getters['iface/parentOfferItems']
    //   }
    // },
    //
    // stasOffersItems: {
    //   get () {
    //     return this.$store.getters['iface/stasOfferItems']
    //   }
    // },

    cabValue: {
      get() {
        return this.$store.getters['iface/cabValue']
      },
      set(value) {
        this.$store.commit(IFACE_CAB_VALUE, value)
      }
    },

    cabItems: {
      get() {
        return this.$store.getters['iface/cabItems']
      }
    },

    drawerRight: {
      get() {
        return this.$store.getters['iface/getDrawerRight']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DRAWER_RIGHT_ON)
        else
          this.$store.commit(IFACE_DRAWER_RIGHT_OFF)
      }
    },

    onModeration: {
      get() {
        return this.$store.getters['iface/isOptimizationOnModeration']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_ON_MODERATION_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_ON_MODERATION_OFF)
      }
    },

    dspTeasersOnModeration: {
      get() {
        return this.$store.getters['iface/isDspTeasersOnModeration']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DSP_TEASERS_ON_MODERATION_ON)
        else
          this.$store.commit(IFACE_DSP_TEASERS_ON_MODERATION_OFF)
      }
    },

    autoPriceIsOff: {
      get() {
        return this.$store.getters['iface/filterAutoPriceIsOff']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_FILTER_AUTOPRICE_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_FILTER_AUTOPRICE_OFF)
      }
    },

    filterDSPShowOnlyEmptyTags: {
      get() {
        return this.$store.getters['iface/filterDSPShowOnlyEmptyTags']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DSP_SHOW_ONLY_EMPTY_TAGS_ON)
        else
          this.$store.commit(IFACE_DSP_SHOW_ONLY_EMPTY_TAGS_OFF)
      }
    },


    isVisibleStat: {
      get() {
        return this.$store.getters['iface/getIsVisibleStatStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DSP_IS_VISIBLE_STAT_ON)
        else
          this.$store.commit(IFACE_DSP_IS_VISIBLE_STAT_OFF)
      }
    },

    isShowStatFromDsp: {
      get() {
        return this.$store.getters['iface/isShowStatFromDsp']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DSP_IS_VISIBLE_STAT_FROM_DSP_ON)
        else
          this.$store.commit(IFACE_DSP_IS_VISIBLE_STAT_FROM_DSP_OFF)
      }
    },

    otiIsOff: {
      get() {
        return this.$store.getters['iface/filterOptimizationIsOff']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_FILTER_OPTIOFF_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_FILTER_OPTIOFF_OFF)
      }
    },

    dspDep: {
      get() {
        return this.$store.getters['iface/getDspDep']
      },
      set(value) {
        this.$store.commit(IFACE_DST_SET_DEP, value)
      }
    },

    dspDepsAndTeams: {
      get() {
        return this.$store.getters['iface/getDspDeps']
      },
      set(value) {
        this.$store.commit(IFACE_DST_SET_DEPS, value)
      }
    },

    depsSources: {
      get() {
        return this.$store.getters['iface/getDspTeasersSources']
      },
      set(value) {
        this.$store.commit(IFACE_DSP_TEASERS_SOURCES_SET, value)
      }
    },

    isAllowDspTeasersAllowMassChanges: {
      get() {
        return this.$store.getters['iface/isAllowDspTeasersAllowMassChanges']
      }
    },

    isDspTeasersMassChanges: {
      get() {
        return this.$store.getters['iface/getDspTeasersMassChanges']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_DSP_TEASERS_MASS_CHANGES_ON)
        else
          this.$store.commit(IFACE_DSP_TEASERS_MASS_CHANGES_OFF)
      }
    },

    optimizationAddNewTask: {
      get() {
        return this.$store.getters['iface/isVisibleOptimizationAddNewTaskWindow']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_OPTIMIZATION_ADD_NEW_TASK_ON)
        else
          this.$store.commit(IFACE_OPTIMIZATION_ADD_NEW_TASK_OFF)
      }
    },
  },

  mounted() {
    if (this.$route.name === 'Index') {
      this.$store.commit(IFACE_DRAWER_LEFT_ON)
    }
    this.onMutate()

    setInterval(() => {
      this.timeInLA = DateTime.local().setZone('America/Los_Angeles').toFormat('HH:mm')
      this.dateInLA = DateTime.local().setZone('America/Los_Angeles').toFormat('yyyy-LL-dd')
    }, 1000)
  },

  methods: {

    onMutate() {
      let height = '0px'
      const toolbar = this.$refs.toolbar

      if (toolbar) {
        height = `${toolbar.$el.offsetHeight}px`
      }

      document.documentElement.style.setProperty('--headerHeight', height)
    },

    onLinkTelegramAccount() {
      api.linkTelegramAccount()
          .then((value) => {
            console.log('onLinkTelegramAccount', value)

            if (value.data.error === false) {
              window.open(`https://t.me/${this.$store.getters['user/getTelegramBotName']}?start=${value.data.token}`, '_blank')
            }
          })
          .catch(e => {
            window.console.error('onLinkTelegramAccount error', e)
          })
    },

    logout() {
      this.dialog = false
      this.$store.dispatch(AUTH_LOGOUT, this).then(() => {
        this.$router.push('/login')
      }).catch((e) => {
        window.console.error(e)
        this.$router.push('/login')
      })
    },

    getUserName() {
      if (this.$store.getters['user/isProfileLoaded']) {
        return this.$store.getters['user/getUserName']
      }
      return ''
    },

    allNewOptimisationIface() {
      // const u = ['denis', 'miro' , 'kate' , 'dovhoselets', 'moskalenko', 'glazunova']
      if (this.$store.getters['user/isProfileLoaded']) {
        return this.$store.getters['user/getUserName']
      }
      return ''
    },

    getAvatar() {
      if (this.$store.getters['user/isProfileLoaded']) {
        return this.$store.getters['user/getGoogleAvatarImg']
      }
      return ''
    },

    getGoogleName() {
      if (this.$store.getters['user/isProfileLoaded']) {
        return this.$store.getters['user/getGoogleName']
      }
      return ''
    },

    getGoogleGivenName() {
      if (this.$store.getters['user/isProfileLoaded']) {
        return this.$store.getters['user/getGoogleGivenName']
      }
      return ''
    },

    getPermissions() {
      if (this.$store.getters['user/isProfileLoaded']) {
        if (this.$store.getters['user/isAdmin']) {
          return 'Administrator'
        }
        return ''
      }
      return ''
    },

    getIsAdmin() {
      return this.$store.getters['user/isAdmin']
    },

    getTitle() {
      if (this.$route.name === 'Index') {
        this.miniVariant = false
        document.title = 'O.Dashboard'
        return 'Home'
      }

      this.miniVariant = true

      if (this.$route.name === 'ErrorInUrl') {
        document.title = 'Show errors in URL'
        return 'Error in URL'
      }

      if (this.$route.name === 'OptimizationStatistic') {
        document.title = 'Optimization statistic'
        return 'Optimization statistic'
      }

      if (this.$route.name === 'SpendingCampaigns') {
        document.title = 'Spending campaigns'
        return 'Spending campaigns'
      }
      if (this.$route.name === 'SpendingTotal') {
        document.title = 'Spending total for cab'
        return 'Spending total for cab'
      }

      if (this.$route.name === 'DSPTeasers') {
        if (this.$route.params.section) {
          document.title = 'DSP Teasers: ' + this.$route.params.section
          return 'DSP Teasers:'
        } else {
          document.title = 'DSP Teasers'
          return 'DSP Teasers:'
        }
      }

      if (this.$route.name === 'EarningsNetworks') {
        document.title = 'Earnings in Affiliate Networks'
        return 'Earnings in Affiliate Networks'
      }

      if (this.$route.name === 'FlowTable') {
        document.title = 'API'
        return 'API'
      }

      if (this.$route.name === 'AffNetwork') {
        if (this.$route.params.network) {
          document.title = 'Affiliate Networks: ' + this.$route.params.network
          return 'Affiliate Networks: ' + this.$route.params.network + ' : ' + this.$route.params.account
        } else {
          document.title = 'Affiliate Networks'
          return 'Affiliate Networks'
        }

      }

    },

    dateFormat(unixtime) {
      return DateTime.fromMillis(unixtime).toFormat('H:mm dd-LL-y')
    }
    ,

    ifaceDrawerLeftSwitch() {
      this.$store.commit(IFACE_DRAWER_LEFT_SWITCH)
    }
    ,

    ifaceDrawerRightSwitch() {
      this.$store.commit(IFACE_DRAWER_RIGHT_SWITCH)
    }
    ,

    ifaceOptimizationAddItemWindowSwitch() {
      this.$store.commit(IFACE_OPTIMIZATION_ADD_ITEM_WINDOW_SWITCH)
    },

    ifaceDspTeasersAddItemWindowSwitch() {
      this.$store.commit(IFACE_DSP_TEASERS_ADD_ITEM_WINDOW_SWITCH)
    },

    ifaceAffNetsAddItemWindowSwitch() {
      this.$store.commit(IFACE_AFFNETS_ADD_ITEM_WINDOW_SWITCH)
    },

    ifaceEarningAddStatisticWindowSwitch() {
      this.$store.commit(IFACE_EARNING_ADD_STATISTIC_WINDOW_SWITCH)
    },

    ifaceApiAddItemWindowSwitch() {
      this.$store.commit(IFACE_API_ADD_ITEM_WINDOW_SWITCH)
    },

    ifaceOptimizationAddNewTaskWindowSwitch() {
      this.$store.commit(IFACE_OPTIMIZATION_ADD_NEW_TASK_SWITCH)
    },

    onR() {
      console.log(this.$route)
    },

    async onUploadFile() {
      try {
        const res = await api.dspUploadFileForParse(this.uploadParserFile, 'DailyReport-T_CPA_KAteDep')
        console.log(res)
        this.download(res.data.fileName, res.data.data)
      } catch (e) {
        console.error(e)
      }
    },

    download(filename, text) {
      const pom = document.createElement('a');
      pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      pom.setAttribute('download', filename);

      if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
      } else {
        pom.click();
      }
    }
  },

  watch: {
    async isProfileLoaded() {
      // console.log('this.isVisibleDsp', this.isVisibleDsp)
      if (this.$store.getters['user/isProfileLoaded']) {
        if (this.isVisibleDsp) {
          try {
            const {data} = await api.getCatalogDsp()
            this.depsSources = data.sources
            // console.log('this.depsSources', this.depsSources)
          } catch (e) {
            console.log('mounted getCatalogDsp', e)
          }
        }
      }
    }
  }
}
</script>
