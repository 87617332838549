<template>
  <v-container fluid>
    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        right
        top
        color="green darken-2"
    >
      Copied : {{ snackbarText }}
    </v-snackbar>
    <!--    <v-dialog v-model="confirmStartStopCampaign" max-width="1000px" v-if="toStartCampaign !== null">-->
    <!--      <v-card>-->
    <!--        <v-card-title class="green" v-if="toStartCampaign.status_name === 'paused'">-->
    <!--          <span class="headline">Start: <b>{{ toStartCampaign.campaign_id }}</b></span>-->
    <!--        </v-card-title>-->

    <!--        <v-card-title class="red" v-if="toStartCampaign.status_name === 'active'">-->
    <!--          <span class="headline">Stop: <b>{{ toStartCampaign.campaign_id }}</b></span>-->
    <!--        </v-card-title>-->

    <!--        <v-card-text>-->
    <!--          <v-container>-->
    <!--            <v-row>-->
    <!--              <v-col cols="12" sm="12" md="12" v-if="toStartCampaign.status_name === 'active'">-->
    <!--                <span class="headline"><b>{{ toStartCampaign.campaign_id }}</b> :  {{ toStartCampaign.name }}</span>-->
    <!--              </v-col>-->
    <!--              <v-col cols="12" sm="12" md="12" v-if="toStartCampaign.status_name === 'paused'">-->
    <!--                <span class="headline"><b>{{ toStartCampaign.campaign_id }}</b> :  {{ toStartCampaign.name }}</span>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--          </v-container>-->
    <!--        </v-card-text>-->

    <!--        <v-card-actions>-->
    <!--          <v-spacer></v-spacer>-->
    <!--          <v-btn color="primary" text @click="toStartCampaign = null; confirmStartStopCampaign = false">Cancel</v-btn>-->
    <!--          <v-btn color="primary" v-if="toStartCampaign.status_name === 'active'"-->
    <!--                 @click="toStartCampaign = null; confirmStartStopCampaign = false">-->
    <!--            Yes-->
    <!--          </v-btn>-->

    <!--          <v-btn color="primary" v-if="toStartCampaign.status_name === 'paused'"-->
    <!--                 @click="toStartCampaign = null; confirmStartStopCampaign = false">-->
    <!--            Yes-->
    <!--          </v-btn>-->
    <!--          <v-spacer></v-spacer>-->
    <!--        </v-card-actions>-->

    <!--      </v-card>-->
    <!--    </v-dialog>-->

    <v-row
        justify="start"
        align="start"
        width="100%"
        class="ml-3 pr-4"
    >
      <v-row>
        <v-col cols="12" sm="1" md="1">
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="430px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  label="Date or Date range"
                  v-model="dateRangeText"
                  prepend-icon="mdi-calendar"
                  @click:prepend="menu = true"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>

            </template>
            <v-card flat>
              <v-row no-gutters>
                <v-col cols="4">

                  <v-list dense>
                    <!--                    <v-subheader class="justify-center"><v-icon >mdi-clock-fast</v-icon></v-subheader>-->
                    <v-subheader class="justify-center">
                      <v-icon>mdi-calendar-range-outline</v-icon>
                    </v-subheader>
                    <v-list-item-group
                        v-model="selectedFastDateRange"
                        color="primary"
                    >
                      <v-list-item
                          v-for="(item, i) in selectedFastDateRangeItems"
                          :key="i"
                      >
                        <!--                        <v-list-item-icon>-->
                        <!--                          <v-icon v-text="item.icon"></v-icon>-->
                        <!--                        </v-list-item-icon>-->
                        <v-list-item-content @click="onClickFastDateInterval(item.value)">
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>

                  </v-list>
                  <v-switch
                      v-if="$store.getters['user/isAdmin']"
                      v-model="switch1" label="Refresh" class="pl-6" color="red"
                      :disabled="isRunningBackdatingUpdateSpent"></v-switch>
                </v-col>
                <v-col cols="8">
                  <v-date-picker
                      v-model="dates"
                      :show-current="todayDate()"
                      reactive
                      first-day-of-week="1"
                      range
                  ></v-date-picker>
                  <!--                  <v-spacer></v-spacer>-->
                  <div class="text-right">
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates);onclickOkPeekDate()"
                    >
                      OK
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>

          </v-menu>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-select
              class="pt-3 mr-0"
              v-model="groupBy"
              label="Group By"
              :items="groupByItems"
              hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              class="pt-1 mr-0"
              v-model="whoAdded"
              label="Who Added"
              :items="whoAddeds"
              hide-details
              dense
              chips
              multiple
              deletable-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              class="pt-1 mr-0"
              v-model="cab"
              :items="cabs"
              return-object
              label="Cab"
              item-text="name"
              item-value="id"
              hide-details
              dense
              chips
              multiple
              deletable-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-select
              class="pt-3 mr-0"
              v-model="campaignType"
              label="Type"
              :items="campaignTypes"
              hide-details
              :menu-props='{maxHeight: 608}'
          >
            <template v-slot:item="{ item }">
              <v-icon class="pr-2">{{ item.icon }}</v-icon>
              {{ item.text }}
            </template>

            <template v-slot:selection="{ item }">
              <v-icon class="pr-2">{{ item.icon }}</v-icon>
              {{ item.text }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-text-field
              v-model="campaignId"
              hide-details
              label="Campaign ID"
              type="string"
              clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-combobox
              class="pt-1 mr-0"
              v-model="region"
              :items="allRegions"
              label="Region"
              chips
              dense
              multiple
              clearable
              hide-details
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              class="pt-4"
              dense
              v-model="offer"
              :items="allOffers"
              label="Offer"
              clearable
          ></v-autocomplete>
        </v-col>
        <!--        <v-col cols="12" sm="1" md="1">-->
        <!--          <v-text-field-->
        <!--              v-model="searchName"-->
        <!--              hide-details-->
        <!--              label="Name"-->
        <!--              type="string"-->
        <!--              clearable-->
        <!--          ></v-text-field>-->
        <!--        </v-col>-->


        <v-col cols="12" sm="1" md="1">
          <v-text-field
              v-model="searchTags"
              hide-details
              label="Tags"
              type="string"
              clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="1">
          <v-combobox
              class="pt-1 mr-0"
              v-model="affNetworkSelected"
              :items="affNetworks"
              chips
              clearable
              label="AffNetworks"
              multiple
              hide-details
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  small
                  @click="select"
                  @click:close="remove(item)"
              >
                <strong>{{ item }}</strong>&nbsp;
              </v-chip>
            </template>
          </v-combobox>
        </v-col>

        <v-col cols="12" sm="1" md="1">
          <v-pagination v-model="page" :total-visible="0" :length="pageCount" class="pt-3"></v-pagination>
        </v-col>

        <v-col cols="12" sm="1" md="1">
          <v-select
              dense
              class="pt-3 mr-0"
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              hide-details
              solo
          ></v-select>
        </v-col>
      </v-row>
      <v-col cols="12" sm="12" md="12" class="pt-0 px-0">
        <v-data-table
            :headers="headers"
            :items="statistic"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :page.sync="page"
            :loading="loading"
            :server-items-length="total"
            :options.sync="options"
            @page-count="pageCount = $event"
            @update:sort-desc="onUpdateSortTable"
            multi-sort
            hide-default-footer
            dense
        >
          <template v-slot:footer>
            <v-row
                v-if="itemsPerPage > 10"
                justify="start"
                align="start"
                width="100%"
                class="pl-3 pr-4 pt-3"
            >
              <v-row>
                <v-col cols="12" sm="5" offset-md="7" md="5">
                  <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </v-col>
              </v-row>
            </v-row>
          </template>

          <template slot="body.prepend">
            <tr class="primary--text">
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy !== 'Date'"></th>
              <th v-if="affNetworkSelected.length>0"></th>
              <th v-if="groupBy === 'Date' && affNetworkSelected.length === 0"></th>
              <th>{{ totalCampaigns }}</th>
              <th>{{ totalCpc }}</th>
              <th>{{ totalSpent }}</th>
              <th v-if="affNetworkSelected.length>0"> {{ totalRevenue }}</th>
              <th v-if="affNetworkSelected.length>0">{{ totalProfit }}</th>
              <th v-if="affNetworkSelected.length>0">{{ totalRoi }} %</th>
              <th>{{ totalSale }}</th>
              <th>{{ totalClicks }}</th>
            </tr>
          </template>

          <template v-slot:item.cabName="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-if="(now - item.cabLastUpdate)/60 > timeoutLastUpdate" v-on="on"
                      class="error--text">{{ item.cabName }}</span>
                <span v-else v-on="on">{{ item.cabName }}</span>
              </template>
              <span>
                <span>Updated {{ (Math.round((now - item.cabLastUpdate) / 60)) }} minutes ago</span>
              </span>
            </v-tooltip>
            <!--            <a target="_blank" rel="noopener noreferrer"-->
            <!--               :href="`https://admin.mgid.com/cab/goodhits/clients?client_currency=&agency=&client_id=${item.id}&login=&email=&domain=&curator=&partner=&inviter=&accompany_manager=&wagesAll=&deposit=&subnet=&color=&periodSelectInterval=&btnsubmit=Filter`"-->
            <!--            >{{ item.id }}-->
            <!--            </a>-->
          </template>

          <template v-if="affNetworkSelected.length>0" v-slot:item.profit="{ item }">
            <div v-if="item.profit < 0 " class="red--text">
              {{ item.profit }}
            </div>
            <div v-if="item.profit > 0">
              {{ item.profit }}
            </div>
          </template>

          <template v-if="affNetworkSelected.length>0" v-slot:item.roi="{ item }">
            <div v-if="item.roi < 0 " class="red--text">
              {{ item.roi }} %
            </div>
            <div v-else-if="item.roi > 0" class="green--text">
              {{ item.roi }} %
            </div>
            <div v-else>
              {{ item.roi }} %
            </div>
          </template>

          <template v-slot:item.campaign_id="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span>
                  <v-icon v-if="item.categoryType === 'dsp'" v-on="on" class="mr-1">mdi-alpha-d-circle-outline</v-icon>
                  <v-icon v-if="item.categoryType === 'content'" v-on="on" class="mr-1">mdi-alpha-c-circle-outline
                  </v-icon>
                  <v-icon v-if="item.categoryType === 'product'" v-on="on" class="mr-1">mdi-cart-outline</v-icon>
                  <v-icon v-if="item.categoryType === 'push'" v-on="on" class="mr-1">mdi-message-badge-outline</v-icon>
                  <v-icon v-if="item.categoryType === 'search_feed'" v-on="on" class="mr-1">mdi-magnify</v-icon>
                  <v-icon v-if="item.categoryType === 'video'" v-on="on" class="mr-1">mdi-video</v-icon>
                </span>

              </template>
              <span>
                <span v-if="item.categoryType === 'dsp'">DSP</span>
                  <span v-if="item.categoryType === 'content'">Content</span>
                  <span v-if="item.categoryType === 'product'">Product</span>
                  <span v-if="item.categoryType === 'push'">Push</span>
                  <span v-if="item.categoryType === 'search_feed'">Search feed</span>
                  <span v-if="item.categoryType === 'video'">Video</span>
              </span>
            </v-tooltip>

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>Content</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>Product</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>push</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>Search feed</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>Video</span>-->
            <!--            </v-tooltip>-->


            <StartStopMenu :key="item.campaign_id" :item="item" @update="getDataFromApi()"></StartStopMenu>

            <!--            <v-menu v-if="item.status_name === 'active'" min-width="500px" offset-y :close-on-content-click="false">-->
            <!--              <template v-slot:activator="{ on: onMenu, attrs: attrsMenu}" >-->
            <!--                <v-tooltip bottom>-->
            <!--                  <template v-slot:activator="{ on, attrs }">-->
            <!--                    <v-hover v-slot="{ hover }">-->
            <!--                      <v-btn icon-->
            <!--                             v-bind="attrs"-->
            <!--                             v-on="{on, ...onMenu, ...attrsMenu}"-->
            <!--                      >-->
            <!--                        <v-icon v-if="!hover" color="green">mdi-play-circle</v-icon>-->
            <!--                        <v-icon v-if="hover" color="red">mdi-pause</v-icon>-->
            <!--                      </v-btn>-->
            <!--                    </v-hover>-->
            <!--                  </template>-->
            <!--                  <span>Stop</span>-->
            <!--                </v-tooltip>-->
            <!--              </template>-->

            <!--              <v-card :close-on-content-click="false">-->
            <!--                <v-card-title class="red">-->
            <!--                  <span class="headline">Stop campaign</span>-->
            <!--                </v-card-title>-->
            <!--                <v-card-text>-->
            <!--                  <v-container>-->
            <!--                    <v-row>-->
            <!--                      <v-col cols="12" sm="12" md="12" >-->
            <!--                        <span class="headline"><b>{{ item.campaign_id }}</b> :  {{ item.name }}</span>-->
            <!--                      </v-col>-->
            <!--                    </v-row>-->
            <!--                  </v-container>-->
            <!--                </v-card-text>-->

            <!--                <v-card-actions>-->
            <!--                  <v-spacer></v-spacer>-->
            <!--                  <v-btn color="primary" @click="toStartCampaign = null">-->
            <!--                    Yes-->
            <!--                  </v-btn>-->
            <!--                </v-card-actions>-->

            <!--              </v-card>-->

            <!--            </v-menu>-->

            <!--            <v-tooltip bottom v-if="item.status_name === 'active'">-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <v-hover v-slot="{ hover }">-->
            <!--                  <v-btn icon-->
            <!--                         v-bind="attrs"-->
            <!--                         v-on="on"-->
            <!--                         @click.stop="toStartCampaign = item; confirmStartStopCampaign =true"-->
            <!--                  >-->
            <!--                    <v-icon v-if="!hover" color="green">mdi-play-circle</v-icon>-->
            <!--                    <v-icon v-if="hover" color="red">mdi-pause</v-icon>-->
            <!--                  </v-btn>-->
            <!--                </v-hover>-->
            <!--              </template>-->
            <!--              <span>Stop</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom v-if="item.status_name === 'paused'">-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <v-hover v-slot="{ hover }">-->
            <!--                  <v-btn icon-->
            <!--                         v-bind="attrs"-->
            <!--                         v-on="on"-->
            <!--                         @click.stop="toStartCampaign = item; confirmStartStopCampaign =true"-->
            <!--                  >-->
            <!--                    <v-icon v-if="hover" color="green">mdi-play-circle</v-icon>-->
            <!--                    <v-icon v-if="!hover" color="red">mdi-pause</v-icon>-->
            <!--                  </v-btn>-->
            <!--                </v-hover>-->
            <!--              </template>-->
            <!--              <span>Start </span>-->
            <!--            </v-tooltip>-->

            <v-tooltip bottom v-if="item.status_name === 'active'">
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <span v-on="on" @click.stop="copyToClipboard(item.campaign_id)"
                        :style="{'cursor': hover ? 'pointer': 'default'}">{{ item.campaign_id }}</span>
                </v-hover>
              </template>
              <span>{{ item.status_name }}</span>
            </v-tooltip>

            <v-tooltip bottom v-else-if="item.status_name">
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <span v-on="on" @click.stop="copyToClipboard(item.campaign_id)"
                        :style="{'cursor': hover ? 'pointer': 'default'}">{{ item.campaign_id }}</span>
                </v-hover>

              </template>
              <span>{{ item.status_name }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <span v-on="on" class="red lighten-2" @click.stop="copyToClipboard(item.campaign_id)"
                        :style="{'cursor': hover ? 'pointer': 'default'}">{{ item.campaign_id }}</span>
                </v-hover>
              </template>
              <span>Unknown status</span>
            </v-tooltip>


          </template>


          <template v-slot:item.limitFilterType="{ item }">
            <LimitsEditor
                @update="getDataFromApi()"
                :key="item.campaign_id"
                :client_id="item.client_id"
                :campaignId="item.campaign_id"
                :limitFilterType="item.limitFilterType"
                :limitFilter_dailyLimit="item.limitFilter_dailyLimit"
                :limitFilter_overallLimit="item.limitFilter_overallLimit"
                :limitFilter_splitDailyLimitEvenly="item.limitFilter_splitDailyLimitEvenly"
            ></LimitsEditor>
          </template>
          <template v-slot:item.payment="{ item }">
            <span v-if="item.payment">{{ Math.round(item.payment / 100) }}</span>
            <span v-else></span>

          </template>
          <!--          <template v-slot:item.categoryType="{ item }">-->
          <!--            <v-tooltip bottom v-if="item.categoryType === 'dsp'">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span v-on="on">DSP</span>-->
          <!--              </template>-->
          <!--              <span>DSP</span>-->
          <!--            </v-tooltip>-->

          <!--            <span v-else-if="item.cabName && item.cabName === 'DSP'">{{ item.name }}</span>-->
          <!--            <v-tooltip bottom v-else-if="item.status_name">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span v-on="on" class="red lighten-2">{{ item.campaign_id }}</span>-->
          <!--              </template>-->
          <!--              <span>{{ item.status_name }}</span>-->
          <!--            </v-tooltip>-->
          <!--            <v-tooltip bottom v-else>-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span v-on="on" class="grey lighten-2">{{ item.campaign_id }}</span>-->
          <!--              </template>-->
          <!--              <span>Unknown status</span>-->
          <!--            </v-tooltip>-->
          <!--          </template>-->

          <template v-slot:item.offer="{ item }">
            <v-tooltip bottom v-if="item.offer">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.offer }}</span>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
            <!--            <span v-if="item.offer">{{item.offer}}</span>-->
            <!--            <span v-else>{{item.name}}</span>-->
            <span v-else-if="item.cabName && item.cabName === 'DSP'">{{ item.name }}</span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="grey lighten-2">{{ item.name }}</span>
              </template>
              <span>Error format name in Campaign Name<br>
                Correct example : "UserName - OfferName_GEO ..."
              </span>
            </v-tooltip>
          </template>

          <template v-slot:item.who_added="{ item }">
            <v-hover
                v-if="!item.isReadyModeration"
                v-slot:default="{ hover }">
              <span
                  :class="{'text-decoration-underline': hover}"
                  :style="{'cursor': hover ? 'pointer': 'default'}"
                  @click.stop="whoAdded.push(item.who_added)"
              >{{ item.who_added }}</span>
            </v-hover>
          </template>

          <template v-slot:item.region="{ item }">
            <v-hover
                v-if="!item.isReadyModeration"
                v-slot:default="{ hover }">
              <span
                  :class="{'text-decoration-underline': hover}"
                  :style="{'cursor': hover ? 'pointer': 'default'}"
                  @click.stop="region = item.region"
              >{{ item.region }}</span>
            </v-hover>
          </template>


        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import api from '@/utils/api'
import {DateTime} from 'luxon'
import {
  IFACE_RUNNING_BACKDATING_UPDATE_SPENT_OFF,
  IFACE_SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS,
  // IFACE_SPENDING_CAMPAIGNS_LAST_UPDATE,
  IFACE_SPENDING_CAMPAIGNS_REFRESH_OFF,
  IFACE_SPENDING_CAMPAIGNS_REFRESH_ON, IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_OFF,
  IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_ON
} from '@/store/actions/iface'

import LimitsEditor from './LimitsEditor'
import StartStopMenu from './StartStopMenu'

export default {
  name: 'Campaigns',
  components: {LimitsEditor, StartStopMenu},
  data() {
    return {
      tz: 'America/Los_Angeles',
      menu: false,
      searchName: null,
      searchTags: null,

      offer: null,
      allOffers: [],

      campaignId: null,

      region: [],
      allRegions: [],

      dates: [],

      options: {itemsPerPage: 20},

      switch1: false,

      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
      headers: [],

      sortBy: ['spent'],
      sortDesc: [true],

      headersForGroupByCampaign: [
        {text: 'Who added', value: 'who_added'},
        // {text: 'Creator', value: 'creator'},
        {text: 'Cab', value: 'cabName',},
        {text: 'Id', value: 'campaign_id'},
        {text: 'Offer', value: 'offer'},
        {text: 'Tags', value: 'tags'},
        {text: 'Region', value: 'region'},
        {text: 'Budget, $', value: 'limitFilterType'},
        {text: 'Payment, $', value: 'payment'},
        // {text: '', value: 'categoryType'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        // {text: 'hold', value: 'decision'},
        // {text: 'reject', value: 'interest'},
        // {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByCampaignWithAff: [
        {text: 'Who added', value: 'who_added'},
        // {text: 'Creator', value: 'creator'},
        {text: 'Cab', value: 'cabName',},
        {text: 'ID', value: 'campaign_id', align: 'center'},
        {text: 'A.Name', value: 'affNetworkName', align: 'center'},
        {text: 'Offer', value: 'offer'},
        {text: 'Tags', value: 'tags'},
        {text: 'Region', value: 'region'},
        {text: 'Budget', value: 'limitFilterType'},
        {text: 'Payment', value: 'payment'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        // {text: 'earning', value: 'earning'},
        {text: 'revenue', value: 'revenue'},
        {text: 'profit', value: 'profit'},
        {text: 'ROI,%', value: 'roi'},
        {text: 'sale', value: 'buying'},
        // {text: 'hold', value: 'decision'},
        // {text: 'reject', value: 'interest'},
        // {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByDate: [
        {text: 'Date', value: 'date'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        // {text: 'hold', value: 'decision'},
        // {text: 'reject', value: 'interest'},
        // {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByDateWithAff: [
        {text: 'Date', value: 'date'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'revenue', value: 'revenue'},
        {text: 'profit', value: 'profit'},
        {text: 'ROI,%', value: 'roi'},
        {text: 'sale', value: 'buying'},
        // {text: 'hold', value: 'decision'},
        // {text: 'reject', value: 'interest'},
        // {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByCreator: [
        {text: 'Creator', value: 'creator'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC,$', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByCab: [
        {text: 'Cab', value: 'cabName'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByRegion: [
        {text: 'Region', value: 'region'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByOffer: [
        {text: 'Offer', value: 'offer'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByWhoAdded: [
        {text: 'Who Add', value: 'who_added'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      statistic: [],
      total: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,

      totalCampaigns: 0,
      totalSpent: 0,
      totalCpc: 0,
      totalSale: 0,
      totalEarning: '',
      totalRevenue: '',
      totalProfit: '',
      totalRoi: '',
      totalHold: 0,
      totalReject: 0,
      totalImps: 0,
      totalClicks: 0,

      selectedFastDateRange: 0,
      selectedFastDateRangeItems: [
        {text: 'Today', value: 'today'},
        {text: 'Yesterday', value: 'yesterday'},
        {text: 'This month', value: 'thisMonth'},
        {text: 'Last month', value: 'lastMonth'},
      ],

      groupBy: 'Campaign',
      groupByItems: ['Campaign', 'Who Added', 'Creator', 'Offer', 'Region', 'Date', 'Cab'],

      campaignType: 'all',
      campaignTypes: [
        {value: 'all', text: 'All'},
        {value: 'product', text: 'Product', icon: 'mdi-cart-outline'},
        {value: 'search_feed', text: 'Search feed', icon: 'mdi-magnify'},
        {value: 'content', text: 'Content', icon: 'mdi-alpha-c-circle-outline'},
        {value: 'push', text: 'Push', icon: 'mdi-message-badge-outline'},
        {value: 'video', text: 'Video', icon: 'mdi-video'},
        {value: 'dsp', text: 'DSP', icon: 'mdi-alpha-d-circle-outline'}
      ],

      whoAdded: ['My'],
      creator: ['My'],
      whoAddeds: [],
      creators: [],

      // cab: [{name: 'All', id: 'all'}],
      cab: [],

      cabs: [],

      affNetworkSelected: [],
      affNetworks: ['All','Tonic', 'Encoreads', 'Iceoffers', 'Jumbleberry', 'Oasis', 'DomainActive'],

      snackbar: false,
      snackbarText: '',

      confirmStartStopCampaign: false,
      toStartCampaign: null,

      now: 0,
      timeoutLastUpdate: 30 // min
    }
  },

  async created() {
    const date = this.todayDate()
    this.dates = [date, date]

    setInterval(() => {
      this.now = Math.round(Date.now() / 1000)
      // console.log(this.now)
    }, 1000)
    //
    const c = await api.getSpendingCatalog()
    //
    Vue.set(this, 'cabs', c.data.cabs)
    // Vue.set(this, 'cabs', c.data.cabs)
    this.$set(this, 'whoAddeds', ['My'].concat(c.data.who_addeds))
    // console.log(c.data.whoAddeds)
    // Vue.set(this, 'creators', ['All', 'My'].concat(c.data.allCreatorsInfo))

    if (this.$store.getters['user/getUserName'] === 'denis') {
      this.creator = 'All'
    }
  },

  async mounted() {
    this.getDataFromApi()
  },

  computed: {
    dateRangeText() {
      if (this.isToday()) return 'Today'
      if (this.isYesterday()) return 'Yesterday'
      if (this.isThisMonth()) return 'This month'
      if (this.isLastMonth()) return 'Last month'
      return this.dates.join(' ~ ')
    },

    refreshTable: {
      get() {
        return this.$store.getters['iface/getSpendingCampaignsRefreshStatus']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SPENDING_CAMPAIGNS_REFRESH_ON)
        else
          this.$store.commit(IFACE_SPENDING_CAMPAIGNS_REFRESH_OFF)
      }
    },

    loading: {
      get() {
        return this.$store.getters['iface/getSpendingCampaignsRefreshIsLoading']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_ON)
        else
          this.$store.commit(IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_OFF)
      }
    },

    isRunningBackdatingUpdateSpent: {
      get() {
        return this.$store.getters['iface/getIsRunningBackdatingUpdateSpent']
      }
    }
  },

  methods: {
    copyToClipboard(text) {
      this.$copyText(text.toString()).then(() => {
        this.snackbarText = text
        this.snackbar = true
      }, (e) => {
        console.log('Copy Error', e)
      })

    },

    todayDate() {
      // const c = DateTime.local().setZone('America/Los_Angeles').toISODate()
      // console.log('cccccccccccc', c, )
      return DateTime.local().setZone(this.tz).toISODate()
    },

    yesterdayDate() {
      return DateTime.local().setZone(this.tz).endOf('day').plus({days: -1}).toISODate()
    },

    thisMonth() {
      return DateTime.local().setZone(this.tz).startOf('month').toISODate()
    },

    isToday() {
      if (this.dates.length === 1)
        return this.todayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.todayDate() === this.dates[0] && this.todayDate() === this.dates[1]

      return false
    },

    isYesterday() {
      if (this.dates.length === 1)
        return this.yesterdayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.yesterdayDate() === this.dates[0] && this.yesterdayDate() === this.dates[1]

      return false
    },

    isThisMonth() {
      if (this.dates.length === 2)
        return this.thisMonth() === this.dates[0] && this.todayDate() === this.dates[1]
      return false
    },

    isLastMonth() {
      const startMonth = DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString()
      const endMonth = DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()

      if (this.dates.length === 2)
        return startMonth === this.dates[0] && endMonth === this.dates[1]
      return false
    },

    onClickFastDateInterval(value) {
      switch (value) {
        case 'today' :
          this.dates = [this.todayDate()]
          break
        case 'yesterday' :
          this.dates = [this.yesterdayDate()]
          break
        case 'thisMonth' :
          this.dates = [this.thisMonth(), this.todayDate()]
          break
        case 'lastMonth' :
          this.dates = [
            DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString(),
            DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()]
          break
        default:
          throw `Unknown ${value}`
      }
    },

    onclickOkPeekDate() {
      this.getDataFromApi()
    },

    onUpdateSortTable() {
      this.getDataFromApi()
    },

    async getDataFromApi() {
      // console.log('getDataFromApi')
      this.loading = true

      let startDate
      let endDate

      let whoAdded
      let creator
      let clientId
      let offer
      let region
      let campaignId
      let tags

      let type

      if (this.campaignType !== 'all') {
        type = this.campaignType
      }

      if (this.dates.length === 1) {
        startDate = this.dates[0]
        endDate = this.dates[0]
      } else if (this.dates.length === 2) {
        startDate = this.dates[0]
        endDate = this.dates[1]
      } else
        throw 'Error dates'

      if (this.switch1) {
        try {
          await api.runFetchCampaignsDayStatistic(startDate, endDate)
        } catch (e) {
          console.error('getDataFromApi', e)
        }
        this.switch1 = false
        this.loading = false
        return
      }

      // if (this.creator === 'All') {
      //   creator = undefined
      // } else if (this.creator === 'My') {
      //   creator = this.$store.getters['user/getUserName']
      // } else {
      //   creator = this.creator
      // }

      if (Array.isArray(this.whoAdded)) {
        let resWhoAdded = []
        this.whoAdded.forEach(w => {
          if (w === 'My') {
            resWhoAdded.push(this.$store.getters['user/getUserName'])
          } else {
            resWhoAdded.push(w)
          }
        })

        if (resWhoAdded.length > 0) {
          whoAdded = resWhoAdded
        }
      }

      // if (this.whoAdded === 'All') {
      //   whoAdded = undefined
      // } else if (this.whoAdded === 'My') {
      //   whoAdded = this.$store.getters['user/getUserName']
      // } else {
      //   whoAdded = this.whoAdded
      // }

      // console.log('whoAdded', whoAdded)
      // console.log('this.cab.name', this.cab)

      if (Array.isArray(this.cab)) {
        let isForAll = false
        let cabs = []

        this.cab.forEach(cab => {
          if (cab.name === 'All') {
            isForAll = true
          } else {
            cabs.push(cab.id)
          }
        })

        if (!isForAll) {
          clientId = cabs
        }
      }


      if (this.region.length > 0)
        region = this.region

      if (this.offer)
        offer = this.offer

      if (this.campaignId)
        campaignId = this.campaignId

      let name

      if (this.searchName !== null) {
        name = this.searchName
      }

      if (this.searchTags !== null) {
        tags = this.searchTags.split(',')
      }

      let affNetworks
      if (Array.isArray(this.affNetworkSelected) && this.affNetworkSelected.length > 0) {
        // console.log(this.affNetworkSelected)
        if (this.affNetworkSelected.some(item => item === 'All')) {
          affNetworks = ['All']
        } else {
          affNetworks = this.affNetworkSelected
        }
      }

      if (this.groupBy !== 'Campaign') {
        const roiIndex = this.sortBy.findIndex(item => item === 'roi')
        if (roiIndex) {
          this.sortBy.splice(roiIndex, 1)
          this.sortDesc.splice(roiIndex, 1)
        }
      }

      switch (this.groupBy) {
        case 'Campaign':
          if (Array.isArray(this.affNetworkSelected) && this.affNetworkSelected.length === 0) {
            Vue.set(this, 'headers', this.headersForGroupByCampaign)
          } else {
            Vue.set(this, 'headers', this.headersForGroupByCampaignWithAff)
          }
          break
        case 'Date':
          if (Array.isArray(this.affNetworkSelected) && this.affNetworkSelected.length === 0) {
            Vue.set(this, 'headers', this.headersForGroupByDate)
          } else {
            Vue.set(this, 'headers', this.headersForGroupByDateWithAff)
          }
          break
        case 'Creator':
          Vue.set(this, 'headers', this.headersForGroupByCreator)
          break
        case 'Cab':
          Vue.set(this, 'headers', this.headersForGroupByCab)
          break
        case 'Region':
          Vue.set(this, 'headers', this.headersForGroupByRegion)
          break
        case 'Offer':
          Vue.set(this, 'headers', this.headersForGroupByOffer)
          break
        case 'Who Added':
          Vue.set(this, 'headers', this.headersForGroupByWhoAdded)
          break
      }

      // this.sortDesc, this.sortBy
      // const sortBy = this.sortBy.length > 0
      //   ?
      //   this.sortBy.reduce((acc, n, index) => {
      //     acc[n] = this.sortDesc[index]
      //     return acc
      //   }, {})
      //   : undefined
      //
      // console.log('sortBy', sortBy)

      const sortBy = this.sortBy.length > 0 ? this.sortBy : undefined
      const sortDesc = this.sortDesc.length > 0 ? this.sortDesc : undefined

      try {
        const res = await api.getCampaignsDayStatistic({
          // , this.page - 1, startDate, endDate, this.groupBy, whoAdded, creator, clientId, name, sortBy, sortDesc, offer, region, campaignId
          limit: this.itemsPerPage,
          page: this.page - 1,
          startDate, endDate,
          groupBy: this.groupBy,
          whoAdded, creator, clientId, name, sortBy, sortDesc, offer, region, campaignId, tags, affNetworks, type
        })
        // console.log('res', res)
        // this.$store.commit(IFACE_SPENDING_CAMPAIGNS_LAST_UPDATE, Math.round((Date.now() / 1000 - res.data.lastUpdate) / 60))
        this.$set(this, 'allOffers', res.data.offers)
        this.$set(this, 'allRegions', res.data.regions)
        this.$set(this, 'whoAddeds', ['My'].concat(res.data.whoAdded))
        this.$set(this, 'creators', ['My'].concat(res.data.creators))
        this.$set(this, 'total', res.data.count)
        this.$set(this, 'statistic', res.data.statistic)
        this.$set(this, 'totalCampaigns', res.data.totalCampaigns)
        this.$set(this, 'totalSpent', res.data.spent)
        this.$set(this, 'totalCpc', res.data.cpc)
        this.$set(this, 'totalRevenue', res.data.revenue)
        this.$set(this, 'totalRoi', res.data.roi)
        this.$set(this, 'totalProfit', res.data.profit)
        this.$set(this, 'totalSale', res.data.buying)
        this.$set(this, 'totalReject', res.data.interest)
        this.$set(this, 'totalHold', res.data.decision)
        this.$set(this, 'totalImps', res.data.imps)
        this.$set(this, 'totalClicks', res.data.clicks)

        if ('fetchStatus' in res.data) {
          this.$store.commit(IFACE_SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS, res.data.fetchStatus)
        } else {
          if (this.isRunningBackdatingUpdateSpent) {
            this.$store.commit(IFACE_RUNNING_BACKDATING_UPDATE_SPENT_OFF)
          }
        }

      } catch (e) {
        console.error('getDataFromApi', e)
      }
      this.loading = false
      this.refreshTable = false
    },

    remove(item) {
      this.affNetworkSelected.splice(this.affNetworkSelected.indexOf(item), 1)
    },
  },

  watch: {
    page() {
      this.getDataFromApi()
    },

    itemsPerPage(value) {
      this.$set(this.options, 'itemsPerPage', parseInt(value, 10))
      this.getDataFromApi()
    },

    whoAdded() {
      this.getDataFromApi()
    },

    creator() {
      this.getDataFromApi()
    },

    groupBy() {
      this.getDataFromApi()
    },

    cab() {
      this.getDataFromApi()
    },

    offer() {
      this.getDataFromApi()
    },

    region() {
      this.getDataFromApi()
    },

    searchName() {
      this.getDataFromApi()
    },

    campaignId() {
      this.getDataFromApi()
    },

    refreshTable(value) {
      if (value) {
        this.getDataFromApi()
      }
    },

    searchTags() {
      this.getDataFromApi()
    },

    campaignType() {
      this.getDataFromApi()
    },

    affNetworkSelected() {
      // if (Array.isArray(value) && value.length === 0) {
      //   Vue.set(this, 'headers', this.headersForGroupByCampaign)
      // } else {
      //   Vue.set(this, 'headers', this.headersForGroupByCampaignWithAff)
      // }
      this.getDataFromApi()
    }
  }
}
</script>

<style scoped>

</style>